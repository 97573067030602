.App {
  text-align: center;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
  background: rgba(204,204,204,.8);
}
::-webkit-scrollbar-thumb:hover{
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
  background:  rgb(64, 64, 64);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.2);
  border-radius: 0;
  background: rgba(0,0,0,.1);
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
@font-face{
  font-family: 'IKEA';
  src:url('ikea.ttf')
}
*{
  font-family: 'IKEA'
}

#menu{
  padding: 10px 0;
  background-color: #fff;
  z-index: 9999;
  box-shadow: 3px 3px 3px #aaa;
  border:1px solid #ccc;
}
#menu ul{
  margin: 0;
  padding: 0;
}
#menu li{
  list-style: none;
  padding: 0 10px ;
  cursor: pointer;
}
#menu li:hover{
  background-color: #1da1f2;
  color: #fff;
}